body {
  margin: 0;
  font-family: "HK Grotesk";
  font-weight: 500;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1; /* offseting bootstrap's 1.5 */
  color: black; /* offseting bootstrap's custom color */
  background-color: #f7f5f3;
}

#root {
  overflow: show;
}
